import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

import PricingTable2 from "../sections/pricing/PricingTable2";
import FAQ from "../sections/pricing/FAQ";
import Helmet from "react-helmet"

const Pricing = () => {
  return (
    <>
      <Helmet>
        <title>HotSnail — Mail Redirection Cost</title>
        <meta name="title" content="HotSnail — Mail Redirection Cost" />
        <meta name="description" content="Mail Redirection & mail scanning costs. View out mail scanning and forwarding price table " />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hotsnail.com.au/" />
        <meta property="og:title" content="HotSnail — Mail Redirection Cost" />
        <meta property="og:description" content="Mail Redirection & mail scanning costs. View out mail scanning and forwarding price table " />
        <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png"></meta>

    </Helmet>
        <PageWrapper footerDark>
          <Section>
            <div className="pt-5"></div>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="6">
                  <Title variant="hero">Pricing & Plans</Title>
                </Col>
              </Row>
              <PricingTable2 />

            </Container>
          </Section>
        </PageWrapper>
    </>
  );
};
export default Pricing;
