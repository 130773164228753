import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Button } from "../../components/Core";

const Table = styled.table`
  thead,
  tr,
  tbody {
    display: block;
  }
  thead tr .sin-title-box {
    padding: 0 20px;
    height: 60px;
    color: #19191b;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.56px;
  }
  .title-col {
    min-width: 290px;
  }
  .basic-col,
  .standard-col,
  .premium-col,
  .ent-col {
    min-width: 195px;
  }

  .colored-row {
    background: #f7f7fb;
    border-top: 2px solid #fff;
    &:first-child {
      border-top: 0;
    }
    .sin-box.title-col {
      color: #19191b;
      font-weight: 700;
    }
    .sin-box {
      position: relative;
      padding: 0 20px;
      height: 65px;
      color: #696871;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      .table-icon {
        font-size: 20px;
        &.neg {
          color: #f04037;
        }
        &.pos {
          color: #67d216;
        }
      }
      &:before {
        content: "";
        background: #fff;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        width: 1px;
      }
    }
  }
`;

const ButtonSm = styled(Button)`
  width: 167px;
  min-width: auto;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  &:hover,
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.secondary}`} !important;
    background-color: ${({ theme }) => `${theme.colors.secondary}`} !important;
    color: #fff;
  }
`;

const PricingTable2 = () => {
  return (
    <>
      <Section className="pt-0">
        <Container>
          <div
            css={`
              overflow: auto;
              padding-bottom: 30px;
            `}
          >
            <Table>
              <thead>
                <tr>
                  <th scope="col" className="title-col sin-title-box"></th>
                  <th scope="col" className="basic-col sin-title-box">
                    Parcel Forwarding
                  </th>

                  <th scope="col" className="basic-col sin-title-box">
                    Pay as you go
                  </th>
                  <th scope="col" className="standard-col sin-title-box">
                    Light
                  </th>
                  <th scope="col" className="premium-col sin-title-box">
                    Flexi-Freedom
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Setup Fee
                  </th>
                  <td className="basic-col sin-box">$0.00</td>
                  <td className="basic-col sin-box">$15.00</td>
                  <td className="standard-col sin-box">$9.00</td>
                  <td className="premium-col sin-box">$0.00</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Monthly service charge
                  </th>
                  <td className="basic-col sin-box">$0.00</td>
                  <td className="basic-col sin-box">$0.00</td>
                  <td className="standard-col sin-box">$6.00</td>
                  <td className="premium-col sin-box">$25.00</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Minimum Mail Deposit*
                  </th>
                  <td className="basic-col sin-box">$10.00</td>
                  <td className="basic-col sin-box">$50.00</td>
                  <td className="standard-col sin-box">$50.00</td>
                  <td className="premium-col sin-box">$50.00</td>
                </tr>

                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    ID free address
                  </th>
                  <td className="basic-col sin-box">Yes</td>
                  <td className="basic-col sin-box">No</td>
                  <td className="standard-col sin-box">No</td>
                  <td className="premium-col sin-box">Yes</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Per preview / Envelope scan*
                  </th>
                  <td className="basic-col sin-box">
                    $3
                  </td>
                  <td className="basic-col sin-box">
                    $3
                  </td>
                  <td className="standard-col sin-box">
                    $1.25
                  </td>
                  <td className="premium-col sin-box">
                    60c
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Scan per letter
                  </th>
                  <td className="basic-col sin-box">
                    N/A
                  </td>
                  <td className="basic-col sin-box">
                    $4
                  </td>
                  <td className="standard-col sin-box">
                    $2.50
                  </td>
                  <td className="premium-col sin-box">
                    $1.50
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Scan per page
                  </th>
                  <td className="basic-col sin-box">
                    N/A
                  </td>
                  <td className="basic-col sin-box">
                    50c
                  </td>
                  <td className="standard-col sin-box">
                    20c
                  </td>
                  <td className="premium-col sin-box">
                    20c
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Shredding
                  </th>
                  <td className="basic-col sin-box">
                    $1.00
                  </td>
                  <td className="basic-col sin-box">
                    25c
                  </td>
                  <td className="standard-col sin-box">
                    20c
                  </td>
                  <td className="premium-col sin-box">
                    Free
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Invoice Removal*
                  </th>
                  <td className="basic-col sin-box">
                    Free
                  </td>
                  <td className="basic-col sin-box">
                    Free
                  </td>
                  <td className="standard-col sin-box">
                    Free
                  </td>
                  <td className="premium-col sin-box">
                    Free
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Credit expires after
                  </th>
                  <td className="basic-col sin-box">
                    90 days
                  </td>
                  <td className="basic-col sin-box">
                    365 days
                  </td>
                  <td className="standard-col sin-box">
                    365 days
                  </td>
                  <td className="premium-col sin-box">
                    365 days
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <thead>
                <tr>
                  <th scope="col" className="title-col sin-title-box">
                    Forwarding (per forward)
                  </th>
                  <th scope="col" className="title-col sin-title-box" colSpan='4'>
                    *excluding postage and packaging
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Forward Mail
                  </th>
                  <td className="basic-col sin-box">
                    $10.00
                  </td>
                  <td className="basic-col sin-box">
                    $7.00
                  </td>
                  <td className="standard-col sin-box">
                    $5.00
                  </td>
                  <td className="premium-col sin-box">
                    $2.50
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Forward Parcel
                  </th>
                  <td className="standard-col sin-box">
                    +20% of cost*
                  </td>
                  <td className="standard-col sin-box">
                    $15.00
                  </td>
                  <td className="premium-col sin-box">
                    $15.00
                  </td>
                  <td className="ent-col sin-box">
                    $8.00
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <thead>
                <tr>
                  <th scope="col" className="title-col sin-title-box">
                    Physical Storage (monthly)
                  </th>
                  <th scope="col" className="title-col sin-title-box" colSpan='4'>
                    *Free digital storage
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    All mail and parcels (&lt;30 days)
                  </th>
                  <td className="basic-col sin-box">
                    Free
                  </td>
                  <td className="basic-col sin-box">
                    Free
                  </td>
                  <td className="standard-col sin-box">
                    Free
                  </td>
                  <td className="premium-col sin-box">
                    Free
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Free letter storage (&gt;30 days)
                  </th>
                  <td className="basic-col sin-box">0</td>
                  <td className="basic-col sin-box">0</td>
                  <td className="standard-col sin-box">
                    0
                  </td>
                  <td className="premium-col sin-box">
                    10
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Small letter (&gt;30 days)
                  </th>
                  <td className="basic-col sin-box">
                    $2
                  </td>
                  <td className="basic-col sin-box">
                    50c
                    </td>
                  <td className="standard-col sin-box">
                    50c
                  </td>
                  <td className="premium-col sin-box">
                    10c
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Medium letter (&gt;30 days)
                  </th>
                  <td className="basic-col sin-box">
                    $2
                    </td>
                  <td className="basic-col sin-box">
                    $1
                    </td>
                  <td className="standard-col sin-box">
                    $1
                  </td>
                  <td className="premium-col sin-box">
                    20c
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Large letter (&gt;30 days)
                  </th>
                  <td className="basic-col sin-box">
                    $2
                    </td>
                  <td className="basic-col sin-box">
                    $2
                    </td>
                  <td className="standard-col sin-box">
                    $2
                  </td>
                  <td className="premium-col sin-box">
                    50c
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Parcel storage fee* (&gt;30 days)
                  </th>
                  <td className="basic-col sin-box">
                    $15
                    </td>
                  <td className="basic-col sin-box">
                    $10
                    </td>
                  <td className="standard-col sin-box">
                    $10
                  </td>
                  <td className="premium-col sin-box">
                    $5
                  </td>
                </tr>

              </tbody>
            </Table>

            <Row style={{ maxWidth: "100%" }}>
              <Col>
                <div style={{ padding: "10px" }}>
                  <small>
                    *Minimum Mail Deposit goes towards your mail credit which is used for forwarding/scanning ect.<br></br>
                    *Per Preview / Envelope Scan - Unopened mail only<br></br>
                    *Physical Storage – by weight category / first 30 days free<br></br>
                    *Minimum forward parcel fee of $10 on the Parcel Forwarding Plan<br></br>
                    *Parcel Storage - Per item, Per Month / first 30 days free<br></br>
                    *Mail items received which do not display a Mail ID will incur $4 search fee (PAYG &amp; Light Plan only)<br></br>
                    *Invoice removal must be requested<br></br>
                    Please note - Pre-paid credit is non-refundable<br></br>
                  </small>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </Section>
    </>
  );
};

export default PricingTable2;
